import { Button, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export const WinningFooter = ({ wonFlag }) => {
  const [disabled, setDisabled] = useState(true);
  // const [timer, setTimer] = useState(5);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setTimer(prevTimer => prevTimer - 1);
  //   }, 1000);

  //   return () => clearTimeout(intervalId);
  // });

  useEffect(() => {
    if (wonFlag) {
      const timer = setTimeout(() => {
        setDisabled(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [wonFlag]);

  return (
    wonFlag && (
      <VStack spacing={10}>
        <Text
          fontSize="9xl"
          textDecorationColor={'green.100'}
          color={'green.400'}
        >
          You WON!!
        </Text>
        <Button
          isDisabled={disabled}
          onClick={() => window.location.reload(false)}
        >
          Play Again
        </Button>
      </VStack>
    )
  );
};
