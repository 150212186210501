import { Button, HStack, Text } from "@chakra-ui/react";

export function NumberConversion({inputNumber, targetNumber}){
    return (
      <HStack
        spacing={3}
        css={{
          justifyContent: 'center',
        }}
      >
        <Text
          fontSize="9xl"
          textDecoration="underline"
          textDecorationColor={'green.100'}
          color={'purple.400'}
          css={{
            textDecorationStyle: 'underline',
            textDecorationThickness: '1px',
            textUnderlineOffset: '20px',
          }}
        >
          {inputNumber}
        </Text>
        <Text fontSize="4xl" color={'green.400'}>
          {' '}
          / {targetNumber}
        </Text>
      </HStack>
    );
}