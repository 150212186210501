import { Box } from '@chakra-ui/react';
import React from 'react';
import Confetti from 'react-confetti';

export const WinningAnimation = ({ won }) => {
  return (
    won && (
        <Confetti delay={100}/>
    )
  );
};
