import { Table, Tbody, Tr, Td, Button } from '@chakra-ui/react';

export function NumbersTable({ changeNumber, startNumber }) {
  const operations = ['x', '+', '-', '÷']; // Replace this with your actual data

  return (
    <Table>
      <Tbody>
        <Tr>
          {operations.map(operation => (
            <Td key={startNumber} textAlign={'center'}>
              <Button
                w={{ base: '50px', md: '60px', lg: '70px' }}
                h={{ base: '50px', md: '60px', lg: '70px' }}
                colorScheme="yellow"
                fontSize={'2xl'}
                color={'blue.500'}
                onClick={() => changeNumber(operation, startNumber)}
              >
                {' '}
                {operation} {startNumber}
              </Button>
            </Td>
          ))}
        </Tr>
        <Tr>
          <Td key={2} textAlign={'center'}>
            <Button
              w={{ base: '50px', md: '60px', lg: '70px' }}
              h={{ base: '50px', md: '60px', lg: '70px' }}
              colorScheme="telegram"
              fontSize={'2xl'}
              onClick={() => changeNumber('*', 2)}
            >
              {' '}
              x 2
            </Button>
          </Td>
          <Td key={1} textAlign={'center'}>
            <Button
              w={{ base: '50px', md: '60px', lg: '70px' }}
              h={{ base: '50px', md: '60px', lg: '70px' }}
              colorScheme="pink"
              fontSize={'2xl'}
              onClick={() => changeNumber('+', 1)}
            >
              {' '}
              + 1
            </Button>
          </Td>
          <Td key={-1} textAlign={'center'}>
            <Button
              w={{ base: '50px', md: '60px', lg: '70px' }}
              h={{ base: '50px', md: '60px', lg: '70px' }}
              colorScheme="pink"
              fontSize={'2xl'}
              onClick={() => changeNumber('-', 1)}
            >
              {' '}
              - 1
            </Button>
          </Td>
          <Td key={'/' + 2} textAlign={'center'}>
            <Button
              w={{ base: '50px', md: '60px', lg: '70px' }}
              h={{ base: '50px', md: '60px', lg: '70px' }}
              colorScheme="telegram"
              fontSize={'2xl'}
              onClick={() => changeNumber('/', 2)}
            >
              {' '}
              ÷ 2
            </Button>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
}

export default NumbersTable;
