import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  HStack,
  Grid,
  useToast,
  Spacer,
} from '@chakra-ui/react';

import { ColorModeSwitcher } from './ColorModeSwitcher';
import { NumberConversion } from './NumberConversion';
import { Operations } from './Operations';
import { WinningAnimation } from './winning';
import { Heading } from './heading';
import clickSound from './assets/click.mp3';
import winSound from './assets/won.mp3';
import customTheme from './theme';
import NumbersTable from './numbers-table';
import { WinningModal } from './win-modal';
import { WinningFooter } from './winning-footer';
const startValue = Math.floor(Math.random() * 30) + 1;
const targetValue = Math.floor(Math.random() * 300) + 30;

function App() {
  const clickAudio = new Audio(clickSound);
  const winAudio = new Audio(winSound);
  const [startNumber, setStartNumber] = useState(startValue);
  const [steps, setSteps] = useState(0);
  const toast = useToast();

  const [won, setWon] = useState(false);

  const [tries, setTries] = useState(1);

  useEffect(() => {
    const triesFromLocalStorage = localStorage.getItem(
      `${startValue}_${targetValue}_tries`
    );
    if (!triesFromLocalStorage) {
      localStorage.setItem(`${startValue}_${targetValue}_tries`, 1);
    } else {
      setTries(triesFromLocalStorage);
    }
  }, []);

  const changeStartNumber = (operator, operand) => {
    if (won) return;
    clickAudio.play();

    let currentNumber = startNumber;
    switch (operator) {
      case '+':
        currentNumber = startNumber + parseInt(operand);
        break;
      case '*':
        currentNumber = startNumber * parseInt(operand);
        break;
      case 'x':
        currentNumber = startNumber * parseInt(operand);
        break;
      case '-':
        currentNumber = startNumber - parseInt(operand);
        break;
      case '/':
        if (startNumber % operand !== 0) {
          if (!toast.isActive(`${startNumber}_${operand}`)) {
            toast({
              title: `${startNumber} is not divisible by ${operand}`,
              status: 'error',
              isClosable: true,
              id: `${startNumber}_${operand}`,
            });
          }
        } else {
          currentNumber = startNumber / parseInt(operand);
        }
        break;
      case '÷':
        if (startNumber % operand !== 0) {
          if (!toast.isActive(`${startNumber}_${operand}`)) {
            toast({
              title: `${startNumber} is not divisible by ${operand}`,
              status: 'error',
              isClosable: true,
              id: `${startNumber}_${operand}`,
            });
          }
        } else {
          currentNumber = startNumber / parseInt(operand);
        }
        break;
      default:
        currentNumber = startNumber + parseInt(operand);
        break;
    }

    if (!checkValidity(currentNumber)) return;

    if (currentNumber == targetValue) {
      setWon(true);
      setStartNumber(currentNumber);
      winAudio.play();
      return;
    }

    if (steps === 0)
      localStorage.setItem(
        `${startValue}_${targetValue}_tries`,
        parseInt(tries) + 1
      );
    setSteps(steps + 1);
    setStartNumber(currentNumber);
  };

  const checkValidity = currentNumber => {
    if (currentNumber > targetValue * 2) {
      if (!toast.isActive('slow_down')) {
        toast({
          title: `Woahh too much please slow down!`,
          status: 'error',
          isClosable: true,
          id: `slow_down`,
        });
      }
      return false;
    } else if (currentNumber <= 0) {
      if (!toast.isActive('go_up')) {
        toast({
          title: `Don't go to 0. Let's Go Up!!`,
          status: 'error',
          isClosable: true,
          id: `go_up`,
        });
      }
      return false;
    } else return true;
  };

  return (
    <ChakraProvider theme={customTheme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="80vh" p={3}>
          <ColorModeSwitcher
            justifySelf="flex-end"
            initialColorMode={{
              initialColorMode: 'dark',
              useSystemColorMode: false,
            }}
          />
          {/* <Heading startValue={startValue} targetValue={targetValue} /> */}
          <Box
            justifySelf={'center'}
            width={{ base: '100%', md: '70%', lg: '50vw' }}
          >
            <HStack
              justifyContent={'space-between'}
              css={{
                padding: '10px',
              }}
            >
              <Text fontSize="2xl" color={'blue.400'}>
                Steps: {steps}
              </Text>
              <Text fontSize="2xl" color={'blue.400'}>
                Try: {tries}
              </Text>
            </HStack>
            <WinningAnimation won={won} />
            <NumberConversion
              inputNumber={startNumber}
              targetNumber={targetValue}
            />
            {!won && (
              <NumbersTable
                changeNumber={changeStartNumber}
                startNumber={startValue}
              />
            )}
            <Spacer />
            {/* <WinningModal wonFlag={won} /> */}
            <WinningFooter wonFlag={won} />
            {/* <Operations
              changeNumber={changeStartNumber}
              startNumber={startValue}
            /> */}
          </Box>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
